import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'Financiamento',
  components: {
    VueSlickCarousel,
    SecaoAvaliar: () => import('src/components/SecaoAvaliar/SecaoAvaliar.vue'),
    FormasAdquirir: () => import('src/components/FormasAdquirir/FormasAdquirir.vue'),
    FinancialServices: () => import('src/components/FinancialServices/FinancialServices.vue'),
    ConfiraTodos: () => import('src/components/ConfiraTodos/ConfiraTodos.vue'),
    ComoFunciona: () => import('src/components/ComoFunciona/ComoFunciona.vue'),
    CategoriaCarrossel: () => import('src/components/CategoriaCarrossel/CategoriaCarrossel.vue')
  },
  meta () {
    return {
      title: 'A melhor opção para financiar seu carro - NetCarros',
      description: {
        name: 'description',
        content: 'Trabalhamos com + 10 bancos, financiamento sem entrada, parcelamos em até 12 vezes no cartao de crédito e financiamentos em até 60 vezes. NetCarros, acesse já!'
      },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-financiamento.jpg' },
      link: { material: { rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath } },
      meta: [
        { 'property': 'og:title', 'content': 'A melhor opção para financiar seu carro - NetCarros' },
        { 'name': 'description', 'content': 'Trabalhamos com + 10 bancos, financiamento sem entrada, parcelamos em até 12 vezes no cartao de crédito e financiamentos em até 60 vezes. NetCarros, acesse já!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-financiamento.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-financiamento.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  monted () {
    this.linkpage = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá,%20tenho%20interesse%20em%20financiar%20a%20minha%20compra,%20quais%20bancos%20vocês%20trabalham?'
    document.querySelector('.botoes a').href = this.linkpage
  },
  methods: {
    scroll () {
      if (this.$refs.financiamento) {
        this.$refs.financiamento.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }
}
